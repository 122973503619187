import React, { useEffect, useRef } from 'react';

function VideoFeed() {
    const videoRef1 = useRef(null);
    const videoRef2 = useRef(null);
    const wsRef = useRef(null);
    const activeRef = useRef(videoRef1); // Keep track of the currently active video element

    useEffect(() => {
        const wsUri = "wss://rgs.bansheeuav.tech/ws";  
        wsRef.current = new WebSocket(wsUri);

        wsRef.current.binaryType = 'arraybuffer';  

        wsRef.current.onopen = function() {
            console.log("Connected to WebSocket server");
        };

        let lastUpdate = 0;
        const frameRateLimit = 25; 

        wsRef.current.onmessage = function(event) { 
            if (event.data instanceof ArrayBuffer) {
                const currentTime = Date.now();
                if (currentTime - lastUpdate >= 1000 / frameRateLimit) {
                    lastUpdate = currentTime;

                    const blob = new Blob([event.data], { type: 'image/jpeg' });
                    const imageUrl = URL.createObjectURL(blob);

                    // Attempt to fix flickering
                    const nextRef = activeRef.current === videoRef1 ? videoRef2 : videoRef1; 

                    if (nextRef.current) {
                        nextRef.current.src = imageUrl;
                        nextRef.current.onload = function() {
                            activeRef.current = nextRef;
                            setTimeout(() => {
                                URL.revokeObjectURL(imageUrl); 
                            }, 100);
                        };
                    }
                }
            }
        };

        wsRef.current.onclose = function() {
            console.log("Disconnected from WebSocket server");
        };

        wsRef.current.onerror = function(error) {
            console.error("WebSocket error: ", error);
        };

        // Cleanup function when component is unmounted
        return () => {
            if (wsRef.current) {
                wsRef.current.close();
            }
        };
    }, []);

    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            backgroundColor: '#f0f0f0',
        }}>
            <img
                ref={videoRef1}
                alt="Live stream will be displayed here"
                style={{
                    border: '2px solid #000',
                    maxWidth: '100%',
                    maxHeight: '100%'
                }}
            />
            <img
                ref={videoRef2}
                alt="Live stream will be displayed here"
                style={{
                    border: '2px solid #000',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    display: 'none' // Initially hidden
                }}
            />
        </div>
    );
}

export default VideoFeed;
